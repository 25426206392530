export const colors = {
  pink: '#eb4d4b',
  cyan: '#7ed6df',
  organe: '#f0932b',
  grey: '#dfe4ea',
  blueHighlight: '#e8f8fd',
  lightTintBlue: '#a3e3f8',
  lightBlue: '#65d1f4',
  lightShadeBlue: '#4c9db7',
  blue: '#0981cd',
  darkTintBlue: '#668ca7',
  darkBlue: '#003e6c',
  darkShadeBlue: '#0d3050',
  tintGreen: '#bcec86',
  green: '#8fe036',
  shadeGreen: '#6ba829',
  tingPurple: '#caa8f5',
  purple: '#9472c0',
  darkPurple: '#2e2733',
  shadePurple: '#592e83',
  tintYellow: '#ffdf8c',
  yellow: '#ffb900',
  shadeYellow: '#ff9719',
  red: '#e61123',
  white: '#ffffff',
  hoverGrey: '#f6f6f6',
  lightTintGrey: '#e2e2e2',
  lightGrey: '#cfcfce',
  lightShadeGrey: '#9b9b9b',
  darkGrey: '#98999a',
  darkShadeGrey: '#727374',
  black: '#333333',
};

export const fontSize = {
  small: '14px',
  normal: '16px',
  large: '18px',
  xLarge: '20px',
  xxLarge: '30px',
};

export const mixins = {
  flexVerticalCenter: (type = 'block') => `
    display: ${type === 'inline' ? 'inline-flex' : 'flex'};
    align-items: center;
  `,

  flexCenter: (type = 'block') => `
    ${mixins.flexVerticalCenter(type)};
    justify-content: center;
  `,

  size: (width, height = width) => `
    width: ${width};
    height: ${height};
  `,

  boxShadow: () => `
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  `,
};
